.words .answer,
.words .guesses {
    margin: 0 auto;
}

.words .guesses {
    width: 355px;
}

.words .guesses.completed {
    max-height: 148px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.words table.answer {
    padding: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.words table.answer td {
    padding-left: 1px;
    padding-right: 1px;
}

.words .guess {
    margin-bottom: 5px;
}

.words .guess input {
    width: 150px;
    text-align: center;
    text-transform: uppercase;
}

.words .guess span.flag {
    display: inline-block;
    width: 10px;
    height: 10px;
    line-height: 10px;
    margin-right: 2px;
    vertical-align: center;
    border: 1px solid;
    border-radius: 4px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.words .guess .option {
    margin-top: 0;
}

.words .actions.lower {
    margin-top: 0;
}

.words .actions .home {
    margin-top: 10px;
}

.words ul {
    width: 300px;
    margin: 0 auto;
    list-style: disc !important;
    background-color: inherit;
    padding: 0;
}

.words li {
    list-style-type: inherit !important;
    display: list-item;
    padding: 0;
    margin: 8px 0;
}

.words .hint svg,
.words .pause svg,
.words .help svg,
.words .save svg,
.words .play-again svg {
    height: 20px;
    margin-right: 5px;
    margin-left: -5px;
}

.words .active {
    border: 1px solid;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
