.help-dialog ul {
  font-size: 0.84em;
}

.help-dialog ul {
  width: 272px;
  margin: 0 auto;
  background-color: inherit;
  padding: 0;
}

.help-dialog li {
  list-style-type: inherit !important;
  display: list-item;
  padding: 0;
  margin: 8px 0;
}

.help-dialog ul.i {
  list-style-type: decimal !important;
}

.help-dialog ul.ii {
  margin-left: -5px;
  width: 270px;
}

.help-dialog li.correct,
.help-dialog li.partial,
.help-dialog li.wrong {
  line-height: 20px;
}

.help-dialog li.correct::before,
.help-dialog li.partial::before,
.help-dialog li.wrong::before {
  display: inline-block;
  width: 20px;
  content: "\2022";
  font-weight: bolder;
  font-size: 36px;
  vertical-align: bottom;
}

.help-dialog.MuiDialogContent-root {
  padding: 0 24px 8px 24px;
}
