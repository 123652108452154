.dark .words .guess span.correct {
    background-color: #54CD54;
}

.dark .words .guess span.partial {
    background-color: #EEEE99;
}

.dark .words .guess span.wrong {
    background-color: #CD5454;
}

.dark .help-dialog li.correct::before {
    color: #54CD54;
}

.dark .help-dialog li.partial::before {
    color: #EEEE99;
}

.dark .help-dialog li.wrong::before {
    color: #CD5454;
}
